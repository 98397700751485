<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import { AspectId, FacetId } from 'search-backend';
import { ref, watch } from 'vue';
import { LocationQueryValue, useRoute } from 'vue-router';
import { AspectMetadata } from '../interfaces/aspects-metadata';
import { getSurfaceForm } from '../interfaces/language';
import { logAddAspect, logAspectSearchInteraction, logRemoveAspect } from '../logging';
import { aspectsModule } from '../modules/aspects-module';

const route = useRoute();

const isOpen = ref(false);

const emit = defineEmits<{
  (e: 'addAspect', aspectId: AspectId): void;
  (e: 'removeAspect', aspectId: AspectId): void;
}>();

// TODO(zsolt): use shortDisplayText
interface AspectMetadataWithSelect extends AspectMetadata {
  aspectId: AspectId;
  isSelected: boolean;
}

let searchInput = ref<HTMLInputElement | undefined>();
let query = ref<string>();
let groupedChips = ref<Map<FacetId, AspectMetadataWithSelect[]>>();

function closeModal() {
  isOpen.value = false;
}

function openModal() {
  isOpen.value = true;
  query.value = '';
  doSearch(true);
  // const el = searchInput.value;
  // if (el) {
  //   el.select();
  //   // el.value = DEFAULT_VALUE;
  //   // //el.setSelectionRange(0, el.value.length);
  //   // el.setSelectionRange(0, DEFAULT_VALUE.length);
  // }
}

function submitIt() {
  return;
}

function buildAspectCollection(): AspectMetadataWithSelect[] {
  return Array.from(aspectsModule.aspects.entries()).map(
    ([aspectId, aspectMetaData]) => ({
      aspectId,
      ...aspectMetaData,
      isSelected: false,
      displayText: getSurfaceForm(aspectMetaData.displayText),
      shortDisplayText: getSurfaceForm(aspectMetaData.shortDisplayText),
    })
  );
}

let aspectIndex: Fuse<AspectMetadataWithSelect> | undefined;
let aspectCollection: AspectMetadataWithSelect[] = [];

function initIndex() {
  const options: Fuse.IFuseOptions<AspectMetadataWithSelect> = {
    includeScore: true,
    keys: ['displayText', 'shortDisplayText'],
    minMatchCharLength: 1,
    findAllMatches: true,
    threshold: 0.3,
    shouldSort: true,
    distance: 20,
    location: 0,
  };
  aspectCollection = buildAspectCollection();
  aspectIndex = new Fuse(aspectCollection, options);
}

initIndex();

function generateGroupedChips(entries: AspectMetadataWithSelect[]) {
  // Search & group results by facet.
  // Keys are in insertion order, which reflects search ranking, so the facet
  // of the top result will be the first.
  groupedChips.value = new Map<FacetId, AspectMetadataWithSelect[]>();
  entries.forEach((item) => {
    const facet = item.facet;
    if (facet === undefined) {
      return;
    }
    if (!groupedChips.value!.has(facet)) {
      groupedChips.value!.set(facet, []);
    }
    const items = groupedChips.value!.get(facet);
    if (items!.length < 10) {
      items!.push(item);
    }
  });
  // groupedChips.value = aspectsModule.facetsToAspectIds;
}

function getStringParamList(
  param: LocationQueryValue | LocationQueryValue[] | null
): string[] | undefined {
  if (typeof param == 'string') {
    return [param];
  } else if (Array.isArray(param) && param.length > 0 && typeof param[0] == 'string') {
    return param as string[];
  }
}

const debouncedLogCallback = debounce((query: string) => {
  logAspectSearchInteraction(query);
}, 1000);

function doSearch(sortChips: boolean) {
  if (!aspectIndex) {
    return;
  }
  if (!query.value) {
    // Results for empty query.
    generateGroupedChips(aspectCollection);
  } else {
    // Do some lazy, debounced logging of the query.
    debouncedLogCallback(query.value);
    generateGroupedChips(
      aspectIndex
        .search({
          $or: [{ displayText: query.value }, { shortDisplayText: query.value }],
        })
        .map((result: Fuse.FuseResult<AspectMetadataWithSelect>) => result.item)
    );
  }
  // Find selected chips.
  const params = getStringParamList(route.query?.filters) ?? [];
  groupedChips.value?.forEach((chips: AspectMetadataWithSelect[]) => {
    chips.forEach((chip: AspectMetadataWithSelect) => {
      chip.isSelected = params.includes(chip.aspectId ?? '');
    });
    if (sortChips) {
      chips.sort((a, b) => (a.isSelected && !b.isSelected ? -1 : 0));
    }
  });
}

watch(
  () => [query.value, route.fullPath],
  () => {
    doSearch(false);
  }
);

function navigateTo(chip: AspectMetadataWithSelect) {
  if (chip.isSelected) {
    logRemoveAspect(chip.aspectId, chip.facet ?? '');
    emit('removeAspect', chip.aspectId);
  } else {
    // closeModal();
    logAddAspect(chip.aspectId, chip.facet ?? '');
    emit('addAspect', chip.aspectId);
  }
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="absolute inset-0 px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-500/30 backdrop-blur-md" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="absolute inset-0 m-2 flex transform flex-col overflow-hidden rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all md:mx-auto md:max-h-[50%] md:max-w-2xl"
            >
              <div class="flex items-center gap-2 border-b p-2 pb-4">
                <div class="flex-1">
                  <label for="search" class="sr-only">Search</label>
                  <form @submit="submitIt" action="javascript:void(0);">
                    <!-- Special hack needed for composing android keyboard. Replace with .eager once implemented in Vue. See https://github.com/vuejs/vue/pull/9814 for details. -->
                    <input
                      id="search"
                      ref="searchInput"
                      name="search"
                      v-model="query"
                      class="block w-full rounded-full border border-slate-300 bg-white py-2 pl-3 pr-3 text-center text-slate-600 placeholder-gray-500 focus:border-slate-500 focus:text-slate-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-slate-500"
                      autocomplete="off"
                      placeholder=""
                      type="text"
                      spellcheck="false"
                      @input="($event.target as any).composing = false"
                    />
                  </form>
                </div>
                <button @click="closeModal">
                  <XMarkIcon class="block h-10 w-10" aria-hidden="true" />
                </button>
              </div>

              <div class="flex flex-shrink flex-col overflow-y-auto">
                <div v-for="([facet, chips], index) in groupedChips" class="">
                  <span class="mt-2 text-xs text-gray-300">{{
                    getSurfaceForm(aspectsModule.facets.get(facet)?.displayText)
                  }}</span>
                  <ul
                    class="chip-carousel no-scrollbar no-scrollbar flex flex-nowrap gap-2 overflow-x-scroll"
                  >
                    <li
                      v-for="chip in chips"
                      :style="
                        'background-color: ' +
                        (chip.isSelected
                          ? aspectsModule.facets.get(chip.facet ?? '')?.chipDarkColor ??
                            'darkgray'
                          : aspectsModule.facets.get(chip.facet ?? '')?.chipColor ??
                            'lightgray') +
                        ';'
                      "
                      :class="[
                        /*chip.isSelected
                          ? FACET_COLOR_DARK[facet]
                          : FACET_COLOR_LIGHT[facet],*/
                        chip.isSelected
                          ? 'border-0 border-black text-white'
                          : 'border border-slate-300 text-black',
                      ]"
                      @click="navigateTo(chip)"
                    >
                      <XMarkIcon
                        v-if="chip.isSelected"
                        class="mr-1 h-4 w-4 flex-shrink-0"
                      />
                      <span class="overflow-hidden overflow-ellipsis">
                        {{ chip.shortDisplayText ?? chip.displayText }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
ul.chip-carousel > li {
  @apply flex flex-shrink-0 items-center whitespace-nowrap rounded-full  p-1 px-2;
}

.no-scrollbar::-webkit-scrollbar {
  /* Chrome, Safari and Opera */
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
