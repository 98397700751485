import { AspectId, FacetId } from 'search-backend';
import { Action, Module, VuexModule } from 'vuex-class-modules';
import { fetchAspects } from '../../data/aspects';
import { AspectMetadata, FacetMetadata } from '../interfaces/aspects-metadata';
import { getSurfaceForm } from '../interfaces/language';
import { store } from '../store';
import { timerStart, timerStop } from '../utils';

// This is an internal variable that stores the resolve callback.
let metadataReadyResolve: (() => void) | undefined = undefined;
// This promise can be used to make sure the metadata has been loaded.
export let aspectsMetadataReadySemaphore: Promise<void> = new Promise((resolve) => {
  metadataReadyResolve = resolve;
});

export const UNKNOWN_FACET: FacetId = 'UnknownFacet';

@Module({ generateMutationSetters: true })
class AspectsModule extends VuexModule {
  aspects: Map<AspectId, AspectMetadata> = new Map();
  facets: Map<FacetId, FacetMetadata> = new Map();
  facetsToAspectIds: Map<FacetId, AspectId[]> = new Map();

  public getFacet(aspectId: AspectId): FacetId | undefined {
    return this.aspects.get(aspectId)?.facet;
  }

  public groupAspectIdsByFacet(
    aspectIds: IterableIterator<AspectId>,
    allowUnknownFacets: boolean = false
  ): Map<FacetId, AspectId[]> {
    const facetToAspects = new Map<FacetId, AspectId[]>();
    const unknownAspects: AspectId[] = [];
    for (let a of aspectIds) {
      const facet = aspectsModule.getFacet(a);
      if (facet !== undefined) {
        if (facetToAspects.has(facet)) {
          facetToAspects.get(facet)!.push(a);
        } else {
          facetToAspects.set(facet, [a]);
        }
      } else {
        unknownAspects.push(a);
      }
    }
    if (allowUnknownFacets && unknownAspects.length > 0) {
      facetToAspects.set(UNKNOWN_FACET, [...unknownAspects]);
    }
    return facetToAspects;
  }

  public getDisplayText(aspectId: AspectId): string | undefined {
    const aspect = aspectsModule.aspects.get(aspectId);
    if (aspect === undefined) {
      console.error(`Unknown aspect ${aspectId}`);
      return undefined;
    } else {
      return getSurfaceForm(aspect.displayText);
    }
  }

  @Action
  public fetchAspects() {
    timerStart('AspectsFromBackend');
    fetchAspects()
      .then(([aspects, facets]) => {
        this.aspects = aspects;
        this.facets = facets;
        this.facetsToAspectIds = this.groupAspectIdsByFacet(this.aspects.keys());
        // We indicate that loading the metadata completed, thus the repo processing can begin.
        metadataReadyResolve!();
      })
      .catch((response) => {
        // Failure: display error message.
        console.error(response);
      })
      .finally(() => {
        timerStop('AspectsFromBackend');
      });
  }
}

export const aspectsModule = new AspectsModule({ store, name: 'aspects' });
aspectsModule.fetchAspects();
