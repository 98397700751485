<script setup lang="ts">
import { intersection, sum } from 'lodash';
import { AspectId } from 'search-backend';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed } from 'vue';

// Core.
import { VisualSelector, VisualSelectorOption } from '../interfaces/visual-selector';
import { logCarouselDepth, logFacetCarouselUsed } from '../logging';
import { searchModule } from '../modules/search-module';

// Client specific.
import { navigator } from '../../navigator';

// Images.
const extraAspectImages = import.meta.glob('/src/assets/*.jpg', {
  eager: true,
  import: 'default',
});

const props = defineProps<{
  selector: VisualSelector;
}>();

// Calculate whether we need to show this selector carousel: we show it if there is no
// intersection between the currently selected ones and the options.
const needsToShow = computed(() => {
  const intersect: AspectId[] = intersection(
    searchModule.searchParams.filters ?? [],
    props.selector.options.flatMap((option: VisualSelectorOption) => option.aspects)
  );
  return intersect.length === 0;
});

const numResultsPerOption = computed(() => {
  const numResults = props.selector.options.map((option: VisualSelectorOption) => {
    const matchingAspects = option.aspects.map((aspect: AspectId) => {
      return searchModule.aspectHistogram?.get(aspect) ?? 0;
    });
    return sum(matchingAspects);
  });
  return numResults;
});

function logIndexChange(swiper: any) {
  if (swiper.activeIndex > 0) {
    logCarouselDepth(
      Math.pow(2, Math.floor(Math.log2(swiper.activeIndex))),
      props.selector.title
    );
  }
}

function logCarouselUsed() {
  // The change was directly caused by the user (vs browser back for example.)
  window.scrollTo(0, 0);
  // Log the click.
  logFacetCarouselUsed(props.selector.title);
}

function getAssetUrl(url: string | undefined): string | undefined {
  if (!url) return undefined;
  // If we don't have a local copy for this url, use it as is (ie. it's a full external
  // http:// link)
  if (!extraAspectImages.hasOwnProperty(url)) return url;
  return extraAspectImages[url] as string;
}
</script>

<template>
  <div v-if="needsToShow" class="">
    <div id="facetCard" class="mx-2 mt-2">
      <span class="text-lg">
        {{ $t(selector.title) }}
      </span>
      <!-- <p>{{ aspectsModule.facets.get(facet)?.description }}</p> -->
    </div>
    <swiper
      id="visualSelector"
      :modules="[FreeMode, Navigation]"
      :free-mode="true"
      :navigation="true"
      slides-per-view="auto"
      class="relative"
      @realIndexChange="logIndexChange"
    >
      <swiper-slide v-for="(option, index) of selector.options">
        <div
          to="/"
          id="aspectCard"
          class="m-2 w-40 rounded-xl shadow-md md:w-48 lg:w-60"
          :style="'background-color: ' + selector.color ?? 'rgb(243 244 246)' + ';'"
        >
          <router-link
            :to="
              navigator.linkSplitFacetSingleCarousel(
                searchModule.searchParams,
                option.aspects
              )
            "
            @click="logCarouselUsed"
          >
            <img
              :src="getAssetUrl(option.image.url)"
              :width="option.image.width"
              :height="option.image.height"
              class="w-40 rounded-t-xl md:w-48 lg:w-60"
              :alt="$t(option.title)"
            />
            <div class="text-center">
              <span class="text-base">{{ $t(option.title) }}</span>
              <span class="text-sm">&nbsp;({{ numResultsPerOption[index] }})</span>
            </div>
            <!-- <p class="overflow-clip text-sm">
            {{ getAspectMeta(aspectId)!.description }}
          </p> -->
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style scoped>
.swiper .swiper-slide,
.swiper-slide {
  width: unset !important;
}
.swiper-button-prev,
.swiper-button-next {
  @apply right-2 h-12 w-8 rounded-full bg-yellow-200/90 text-xs text-black shadow-lg focus:ring-0 sm:visible !important;
  --swiper-navigation-size: 1.2rem;
  font-weight: 800;
  /* Compensate for the skewed vertical position. */
  margin-top: -20px;
}
.swiper-button-disabled {
  visibility: hidden;
}
</style>
