// Lib to handle overlapping ranges.
import { cloneDeep } from 'lodash';
import { NumericRange, NumericRanges } from '../interfaces/search-params';

export function isInSingleRange(range: NumericRange, value?: number) {
  return value !== undefined && value >= range.min && value <= range.max;
}

export function isInNumericRanges(
  ranges: NumericRanges | undefined,
  value: number | undefined
): boolean {
  if (ranges === undefined) return true;
  if (Array.isArray(ranges)) {
    for (const r of ranges) {
      if (isInSingleRange(r, value)) {
        return true;
      }
    }
    return false;
  } else {
    return isInSingleRange(ranges as NumericRange, value);
  }
}

export function canonicalizeNumericRanges(
  range: NumericRanges | undefined
): NumericRange[] {
  if (range === undefined) return [];
  if (!Array.isArray(range)) return [range];
  // Union of ranges -- we should merge them whenever possible.
  // We need to clone deeply otherwise the actual ranges of the vuex module are modified and the store fires an error.
  const sortedRanges = cloneDeep(range).sort((a: NumericRange, b: NumericRange) => {
    return a.min === b.min ? b.max - a.max : a.min - b.min;
  });
  const mergedRanges: NumericRange[] = [];
  sortedRanges.forEach((currentRange: NumericRange) => {
    const length = mergedRanges.length;
    if (length === 0) {
      // First round.
      mergedRanges.push(currentRange);
    } else if (mergedRanges[length - 1].max < currentRange.min) {
      // No overlap with the latest one.
      mergedRanges.push(currentRange);
    } else {
      // Overlap: extend the max of the range to cover both intervals.
      mergedRanges[length - 1].max = Math.max(
        mergedRanges[length - 1].max,
        currentRange.max
      );
    }
  });

  return mergedRanges;
}

function isRangeCovered(outer: NumericRange[], inner: NumericRange): boolean {
  for (const r of outer) {
    if (r.min <= inner.min && inner.max <= r.max) {
      return true;
    }
  }
  return false;
}

export function coversNumericRanges(
  outer: NumericRanges,
  inner: NumericRanges
): boolean {
  const canonicalOuter = canonicalizeNumericRanges(outer);
  const canonicalInner = canonicalizeNumericRanges(inner);
  for (const innerRange of canonicalInner) {
    if (!isRangeCovered(canonicalOuter, innerRange)) return false;
  }
  return true;
}
