import { AspectId, FacetId } from 'search-backend';
import { AspectMetadata, FacetMetadata } from '../core/interfaces/aspects-metadata';
import { VisualSelector } from '../core/interfaces/visual-selector';
import { ASPECTS, FACETS } from './aspects-data';

type AspectMetadataMap = Map<AspectId, AspectMetadata>;
type FacetMetadataMap = Map<FacetId, FacetMetadata>;

/************************************************************************
 * Exported functions, data and interfaces as part of the framework
 ************************************************************************/

// fetchAspects
//
// Loads the aspects and facets and returns a Promise for a pair of the respective maps.
//
// There is no restriction on how we load this data: it could happen as an import or
// through axios. It's better be fast though, as the search and ui is blocking on this
// data.
export async function fetchAspects(): Promise<[AspectMetadataMap, FacetMetadataMap]> {
  return [ASPECTS, FACETS];
}

// visibleFacets
//
// The facets that are shown in the filter menu.
//
// TODO: Move this into the facet metadata map and handle it in aspects module.
export const visibleFacets: FacetId[] = [
  'issue',
  'topic'
];

export const VISUAL_SELECTORS: VisualSelector[] = [
  
  ];
