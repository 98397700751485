import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store, createLogger } from 'vuex';
import { VuexModule } from 'vuex-class-modules';
import { isDevelopmentMode } from './utils';

// The store state is defined in separate modules.

export const key: InjectionKey<Store<{}>> = Symbol();

const logger = createLogger({
  collapsed: false, // auto-expand logged mutations
  logActions: true,
  logMutations: true,
  logger: console, // implementation of the `console` API, default `console`
});

// Make vuex class module reload smooth.
// https://github.com/gertqin/vuex-class-modules/issues/51
// https://github.com/gertqin/vuex-class-modules/#vite-hmr
VuexModule.__useHotUpdate = isDevelopmentMode();

export const store = createStore<{}>({
  strict: isDevelopmentMode(),
  plugins: isDevelopmentMode() ? [logger] : [],
});

export function useStore() {
  return baseUseStore(key);
}
