<script setup lang="ts">
// Global.
import { AspectId, FacetId } from 'search-backend';
import { computed, Ref, ref, watch } from 'vue';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
  ViewColumnsIcon,
} from '@heroicons/vue/24/outline';
import { ViewColumnsIcon as ViewColumnsIconSolid } from '@heroicons/vue/24/solid';

// Framework.
import { SearchParams } from '../interfaces/search-params';
import {
  logAddAspect,
  logFilterPanelInteraction,
  logRemoveAspect,
  logToggleSplitView,
} from '../logging';
import { aspectsModule } from '../modules/aspects-module';
import { searchModule } from '../modules/search-module';

// Vertical specific.
import { cloneDeep } from 'lodash';
import { visibleFacets } from '../../data/aspects';
import { LanguageDependentString, getSurfaceForm } from '../interfaces/language';

defineProps<{ showSplitFacetButton?: Boolean }>();

// This is the canonical model that syncs to the checkboxes in the UI.
interface VisibleAspect {
  aspectId: AspectId;
  displayText: LanguageDependentString | undefined;
  secondaryDisplayText: LanguageDependentString | undefined;
  checked: boolean;
  // The number of results we have in search. We use the undefined value to indicate
  // that we don't need to show this (b/c it's a sibling of a selected aspect).
  numResults?: number;
}

const visibleFacetToAspects = ref<[FacetId, VisibleAspect[]][]>();

// This is just a shorthand so that we can easily have a view of visibleFacetToAspects
// with only the selected aspects.
const perFacetCheckedAspects: Ref<Map<FacetId, AspectId[]>> = computed(() => {
  const result = new Map<FacetId, AspectId[]>();
  visibleFacetToAspects.value?.forEach(([facet, aspects]) => {
    const filters = aspects
      .filter((va: VisibleAspect) => va.checked)
      .map((va: VisibleAspect) => va.aspectId);
    if (filters.length > 0) {
      // If we have a selected aspect for this facet we make sure that all aspects under
      // this facet are enabled and we don't show a number for them.
      aspects.forEach((va: VisibleAspect) => {
        va.numResults = undefined;
      });
      result.set(facet, filters);
    }
  });
  return result;
});

// Ui elements.
let visibleBlock = ref<FacetId | undefined>();
const splitFacet = computed(() => searchModule.searchParams.splitFacet);

function initVisibleFacetToAspects(filters: AspectId[]) {
  visibleFacetToAspects.value = visibleFacetToAspects.value?.map(([facet, aspects]) => {
    const result = [
      facet,
      aspects.map((va: VisibleAspect) => {
        return {
          ...va,
          checked: filters.includes(va.aspectId),
          numResults: searchModule.aspectHistogram?.get(va.aspectId),
        } as VisibleAspect;
      }),
    ];
    return result as [FacetId, VisibleAspect[]];
  });
}

function searchParamsToSelectedAspects(searchParams: SearchParams): AspectId[] {
  let filters: AspectId[] = [...(searchParams.filters ?? [])];
  return filters;
}

watch(
  () => searchModule.searchParams,
  () => {
    initVisibleFacetToAspects(searchParamsToSelectedAspects(searchModule.searchParams));
  },
  // Makes the watcher to run on init.
  { immediate: true }
);

watch(
  () => aspectsModule.facetsToAspectIds,
  (newFacetsToAspectIds: Map<FacetId, AspectId[]>) => {
    visibleFacetToAspects.value = visibleFacets.map((f: FacetId) => [
      f,
      (newFacetsToAspectIds.get(f) ?? []).map((aspectId) => ({
        aspectId,
        displayText: aspectsModule.aspects.get(aspectId)?.displayText,
        secondaryDisplayText: aspectsModule.aspects.get(aspectId)?.secondaryDisplayText,
        checked: false,
        numResults: searchModule.aspectHistogram?.get(aspectId),
      })),
    ]);
    initVisibleFacetToAspects(searchParamsToSelectedAspects(searchModule.searchParams));
  },
  { immediate: true }
);

// Update the counts on the filter panel once the histograms arrive.
watch(
  () => searchModule.aspectHistogram,
  () => {
    if (searchModule.aspectHistogram !== undefined) {
      visibleFacetToAspects.value?.forEach(
        ([facet, aspects]: [FacetId, VisibleAspect[]]) => {
          aspects.forEach((va: VisibleAspect) => {
            if (searchModule.aspectHistogram!.has(va.aspectId)) {
              va.numResults = searchModule.aspectHistogram!.get(va.aspectId);
            } else {
              va.numResults = 0;
            }
          });
        }
      );
      visibleFacetToAspects.value = cloneDeep(visibleFacetToAspects.value);
    }
  }
);

function clickHeader(facetId: FacetId) {
  visibleBlock.value = visibleBlock.value === facetId ? undefined : facetId;
}

function toggleSplit(facetId: FacetId) {
  window.scrollTo(0, 0);
  searchModule.doSearch({
    ...searchModule.searchParams,
    splitFacet: splitFacet.value === facetId ? undefined : facetId,
  });
  logToggleSplitView(facetId);
}

function deleteChecks(facetId: FacetId) {
  visibleFacetToAspects.value?.forEach(([_facetId, visibleAspects]) => {
    if (_facetId == facetId) {
      visibleAspects.forEach((visibleAspect: VisibleAspect) => {
        visibleAspect.checked = false;
      });
    }
  });
  logFilterPanelInteraction();
  logRemoveAspect('@ALL', facetId);
}

function logChangedVisibleAspect(aspect: VisibleAspect, facet: FacetId) {
  logFilterPanelInteraction();
  // Since we are listening to the @click event, aspect.checked is still the old value.
  if (!aspect.checked) {
    logAddAspect(aspect.aspectId, facet);
  } else {
    logRemoveAspect(aspect.aspectId, facet);
  }
}

function searchParamsFromFacetedSelectedAspects(
  perFacetSelectedAspects: Map<FacetId, AspectId[]>
): SearchParams {
  const searchParams = { ...searchModule.searchParams };
  delete searchParams.price;
  // Make sure that we retain the aspects that are not controlled by visible aspects.
  const visibleAspectIds: Set<AspectId> = new Set(
    visibleFacetToAspects.value?.flatMap(([_, aspects]) =>
      aspects.map((a: VisibleAspect) => a.aspectId)
    )
  );
  searchParams.filters = [...(searchModule.searchParams.filters ?? [])].filter(
    (a: AspectId) => !visibleAspectIds.has(a)
  );
  // For visible aspects, we use the perFacetSelectedAspects map (our vmodel).
  perFacetSelectedAspects.forEach((aspects: AspectId[], facet: FacetId) => {
    searchParams.filters?.push(...aspects);
  });
  return searchParams;
}

function search() {
  searchModule.doSearch({
    ...searchParamsFromFacetedSelectedAspects(perFacetCheckedAspects.value),
    // Remove split facet if we selected an aspect from there.
    splitFacet:
      splitFacet.value && perFacetCheckedAspects.value.has(splitFacet.value)
        ? undefined
        : splitFacet.value,
  });
}

// This will start the search whenever the perFacetCheckedAspects change.
watch(
  () => perFacetCheckedAspects.value,
  () => search()
);
</script>

<template>
  <form class="flex flex-col divide-y bg-white p-2" @submit.enter.prevent="">
    <!-- <h2 class="text-2xl capitalize">{{ $t('message.search carpets') }}</h2> -->

    <!-- Generic block for facet -> aspect* checkboxes. -->
    <div
      class="py-2"
      v-for="[facetId, visibleAspects] in visibleFacetToAspects"
      :value="facetId"
      @click="logFilterPanelInteraction"
    >
      <!-- Header of the accordion -->
      <h3
        class="flex w-full items-center justify-between text-lg font-bold capitalize"
        @click="clickHeader(facetId)"
      >
        <ChevronUpIcon v-if="visibleBlock === facetId" class="h-6 w-6" />
        <ChevronDownIcon v-else class="h-6 w-6" />
        <span class="ml-2">{{
          getSurfaceForm(aspectsModule.facets.get(facetId)?.displayText)
        }}</span>
        <span class="flex-1"></span>
        <!-- only show the badge when the accordion is not open for this one. -->
        <span
          v-if="perFacetCheckedAspects.get(facetId) && visibleBlock != facetId"
          class="mr-2 font-normal"
          >({{ perFacetCheckedAspects.get(facetId)!.length }})</span
        >

        <!-- Remove selection -->
        <TrashIcon
          v-if="visibleBlock == facetId && perFacetCheckedAspects.has(facetId)"
          @click.stop="deleteChecks(facetId)"
          class="h-6 w-6 text-gray-600"
        />

        <ViewColumnsIconSolid
          class="h-6 w-6 rotate-90"
          v-if="showSplitFacetButton == true && splitFacet == facetId"
          @click.stop="toggleSplit(facetId)"
        />
        <ViewColumnsIcon
          v-else-if="
            showSplitFacetButton == true &&
            visibleBlock === facetId &&
            perFacetCheckedAspects.get(facetId) === undefined
          "
          class="h-6 w-6 rotate-90"
          @click.stop="toggleSplit(facetId)"
        />
      </h3>
      <!-- Contents of the accordion -->
      <div
        :class="[visibleBlock === facetId ? 'visible-accordion' : 'hidden-accordion']"
      >
        <div class="h-1" />
        <label
          v-for="visibleAspect in visibleAspects"
          class="flex max-w-full items-center justify-start p-1"
        >
          <input
            :name="`${facetId}[]`"
            :value="visibleAspect"
            type="checkbox"
            v-model="visibleAspect.checked"
            @change="logChangedVisibleAspect(visibleAspect, facetId)"
            :disabled="visibleAspect.numResults === 0 && !visibleAspect.checked"
            class="mr-3 ml-2.5"
          />
          <div class="flex flex-col items-start">
            <div class="flex-1 overflow-x-hidden text-ellipsis whitespace-nowrap">
              <span class="">
                {{ getSurfaceForm(visibleAspect.displayText) }}
              </span>
              <span
                v-if="visibleAspect.numResults !== undefined"
                class="text-sm italic text-gray-400"
                >&nbsp; ({{ visibleAspect.numResults }})
              </span>
            </div>
            <span
              v-if="visibleAspect.secondaryDisplayText"
              class="text-sm text-gray-400"
              >{{ visibleAspect.secondaryDisplayText }}</span
            >
          </div>
        </label>
      </div>
    </div>
  </form>
</template>

<style scoped>
.hidden-accordion {
  max-height: 0px;
  transition: max-height 0.15s ease-out;
  overflow-y: hidden;
}
.visible-accordion {
  max-height: 1000px;
  transition: max-height 0.25s ease-in;
  overflow-y: hidden;
}
</style>
