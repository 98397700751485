import { cloneDeep, findIndex, isEqualWith, sortBy } from 'lodash';
import {
  AspectHistogram,
  AspectId,
  SearchRequest,
  SearchResponse,
} from 'search-backend';
import { nextTick } from 'vue';
import { Action, Module, VuexModule } from 'vuex-class-modules';
import WebworkerPromise from 'webworker-promise';

import { navigator } from '../../navigator';
import { router } from '../../router';
import { numericRangesLength, SearchParams } from '../interfaces/search-params';
import {
  CanonicalResultWithAlternatives,
  GroupedSearchResponse,
} from '../interfaces/search-response';
import { searchParamsToSearchRequest } from '../libs/search-params';
import SearchWorker from '../libs/search-worker?worker';
import {
  orderInsensitiveArray,
  removeUndefinedValues,
  timerStart,
  timerStop,
} from '../utils';
import { store } from './../store';
import { aspectsMetadataReadySemaphore, aspectsModule } from './aspects-module';

import { Item } from '../../data/repo';
import { DEFAULT_SEARCH_PARAMS } from '../../libs/search-params';

const BATCH_LOAD_SIZE = 20;
const NUM_CAROUSEL_ELEMENTS = 20;

export interface ResultGroup {
  topResults: Readonly<CanonicalResultWithAlternatives>[];
  numResults: number;
}
type GroupedResultsMap = Array<[AspectId, ResultGroup]>;

// We keep a cache of the search results here. A bit wasteful given that the search
// backend also stores it, but it makes our lives easier.
let cachedSearchResults: Readonly<CanonicalResultWithAlternatives>[] = [];

// Set up the search worker and handle the events.
const searchWorker = new WebworkerPromise(new SearchWorker());

// Docinfo does not need search.
export async function fetchProduct(
  productId: string
): Promise<CanonicalResultWithAlternatives | undefined> {
  return searchWorker.exec('DOCINFO', productId);
}

export async function fetchProductByCanonicalIdWithSearchParams(payload: {
  canonicalId: string;
  searchParams?: SearchParams;
}): Promise<CanonicalResultWithAlternatives | undefined> {
  const searchRequest: SearchRequest = {
    canonicalId: payload.canonicalId,
    ...searchParamsToSearchRequest(payload.searchParams ?? {}),
  };
  return searchWorker
    .exec('SEARCH', cloneDeep(searchRequest))
    .then((response: GroupedSearchResponse) => {
      return response.results && response.numResults > 0
        ? CanonicalResultWithAlternatives.fromSerializedObject(response.results[0])
        : undefined;
    });
}

@Module({ generateMutationSetters: true })
class SearchModule extends VuexModule {
  private searchParamsInternal: SearchParams = { ...DEFAULT_SEARCH_PARAMS };
  private numResultsShown: number = BATCH_LOAD_SIZE;
  public numSearchResults = 0;
  public groupedResults: Readonly<GroupedResultsMap> = new Array<
    [AspectId, ResultGroup]
  >();
  public isLoading: boolean = false;
  public aspectHistogram?: AspectHistogram;

  get searchResultsToShow(): Readonly<CanonicalResultWithAlternatives>[] {
    return this.numSearchResults > 0
      ? cachedSearchResults.slice(0, this.numResultsShown)
      : [];
  }

  @Action
  private async computeGroupedResults(initial: boolean) {
    if (aspectsMetadataReadySemaphore) {
      await aspectsMetadataReadySemaphore;
    }
    if (this.searchParamsInternal.splitFacet === undefined) {
      this.groupedResults = [];
      return;
    }
    timerStart('computeGroupedResults');
    const aspectToProducts = new Map<AspectId, ResultGroup>();
    const orderedAspects = aspectsModule.facetsToAspectIds.get(
      this.searchParamsInternal.splitFacet!
    );

    cachedSearchResults.forEach((item: Readonly<CanonicalResultWithAlternatives>) => {
      const aspect = orderedAspects?.find((a: AspectId) =>
        item.canonicalResult.aspects?.hasOwnProperty(a)
      );
      if (aspect == undefined) {
        return;
      }
      if (!aspectToProducts.has(aspect)) {
        aspectToProducts.set(aspect, {
          topResults: new Array<CanonicalResultWithAlternatives>(),
          numResults: 0,
        });
      }
      const resultGroup = aspectToProducts.get(aspect);
      if (resultGroup) {
        if (resultGroup.topResults.length < (initial ? 3 : NUM_CAROUSEL_ELEMENTS)) {
          resultGroup.topResults.push(item);
        }
        ++resultGroup.numResults;
      }
    });

    // Order carousels by
    // - aspect order within facet
    // - length (of the < 20 item carousels)
    // Sort by the within-facet order first.
    const sortedByKeys = sortBy([...aspectToProducts.entries()], (a) =>
      findIndex(orderedAspects ?? [], (aspect: AspectId) => aspect === a[0])
    );
    // Use lodash stable sort to shuffle the shorter lists but keep the order within the
    // carousels with 20 elements.
    this.groupedResults = Array.from(
      new Map<AspectId, ResultGroup>(
        sortBy(sortedByKeys, (a) => -a[1].topResults.length)
      ).entries()
    ).slice(0, initial ? 3 : 100);
    timerStop('computeGroupedResults');
  }

  get searchParams(): Readonly<SearchParams> {
    return this.searchParamsInternal;
  }

  get numFilters(): number {
    const num =
      (this.searchParamsInternal.filters?.length ?? 0) +
      numericRangesLength(this.searchParamsInternal.price) +
      Math.max(
        numericRangesLength(this.searchParamsInternal.width),
        numericRangesLength(this.searchParamsInternal.height)
      ) +
      (this.searchParamsInternal.id !== undefined ? 1 : 0);
    return num;
  }

  @Action
  doSearch(searchParams: SearchParams) {
    const searchParamsClean = removeUndefinedValues(
      searchParams
    ) as unknown as SearchParams;
    if (
      isEqualWith(this.searchParamsInternal, searchParamsClean, orderInsensitiveArray)
    ) {
      return;
    }
    this.isLoading = true;
    this.numSearchResults = 0;
    this.groupedResults = [];
    cachedSearchResults = [];
    this.searchParamsInternal = searchParamsClean;
    router.push(navigator.getRouteWithSearchParams(searchParamsClean));
    searchWorker
      .exec('SEARCH', cloneDeep(searchParamsToSearchRequest(this.searchParams)))
      .then((response: GroupedSearchResponse) => {
        searchModule.processSearchResponse(response);
      });
  }

  @Action
  processSearchResponse(response: GroupedSearchResponse | undefined) {
    timerStart('processSearchResponse');
    if (response === undefined) {
      console.error('No search response.');
    } else {
      cachedSearchResults = response.results.map(
        CanonicalResultWithAlternatives.fromSerializedObject
      );
    }
    this.numSearchResults = cachedSearchResults.length;
    this.numResultsShown = BATCH_LOAD_SIZE;
    this.aspectHistogram = response?.aspectHistogram;
    timerStart('SetGroupedResults');
    this.computeGroupedResults(true);
    timerStop('SetGroupedResults');
    this.isLoading = false;
    // Load more elements for the swiper but only after the first few are displayed.
    setTimeout(() => {
      searchModule.computeGroupedResults(false);
    }, 100);
    timerStop('processSearchResponse');
  }

  // Returns true when everything has been loaded, ie. we have no more results.
  @Action
  async loadMore(): Promise<boolean> {
    if (this.numResultsShown < this.numSearchResults) {
      this.numResultsShown = Math.min(
        this.numResultsShown + BATCH_LOAD_SIZE,
        this.numSearchResults
      );
    }
    await nextTick();
    return this.numSearchResults > 0 && this.numResultsShown >= this.numSearchResults;
  }
}

export const searchModule = new SearchModule({ store, name: 'search' });
