import { isEqual } from 'lodash';
import { AndClause, AspectId, OrClause, SearchRequest } from 'search-backend';
import { LocationQuery, LocationQueryValue } from 'vue-router';
import { DEFAULT_SEARCH_PARAMS } from '../../libs/search-params';
import { NumericRange, NumericRanges, SearchParams } from '../interfaces/search-params';
import { aspectsModule } from '../modules/aspects-module';
import { castToArray } from '../utils';
import { canonicalizeNumericRanges } from './ranges';

// Types for SearchParams fields.
const floatParams = ['aspectsDiversityMaxScoreDiff'] as const;
const intParams = ['aspectsDiversityLookahead'] as const;
const boolParams = ['enableAspectsDiversityTwiddler'] as const;
const numericRangesParams = ['width', 'height', 'price'] as const;

function getSelectedAspectsAsArray(
  filters: LocationQueryValue[] | LocationQueryValue | undefined
): AspectId[] {
  if (filters === undefined) {
    return [];
  } else if (Array.isArray(filters)) {
    return Object.assign([], filters);
  }
  return [filters as AspectId];
}

function getNumericRange(value: string): NumericRange | undefined {
  const rangeValues = value.split(',');
  if (rangeValues.length !== 2) {
    console.error('Wrong number of fields in numeric range ', value);
    return undefined;
  }
  return {
    min: parseInt(rangeValues[0]),
    max: parseInt(rangeValues[1]),
  } as NumericRange;
}

function getCanonicalNumericRanges(
  field: LocationQueryValue[] | LocationQueryValue | undefined
): NumericRange[] {
  if (field === undefined) {
    return [];
  } else if (Array.isArray(field)) {
    return canonicalizeNumericRanges(
      field
        .map((v: LocationQueryValue) => {
          return getNumericRange(v as string);
        })
        .filter((v) => v) as NumericRanges
    );
  } else {
    return canonicalizeNumericRanges(getNumericRange(field as string));
  }
}

export function getSearchParamsFromLocationQuery(
  experimentSearchParams: Partial<SearchParams>,
  query: LocationQuery
): SearchParams {
  // Get thes string params and selected aspects.
  let searchParams: SearchParams = {
    ...DEFAULT_SEARCH_PARAMS,
    ...experimentSearchParams,
    ...query,
    filters: castToArray<AspectId>(query.filters as AspectId | AspectId[] | undefined),
  };

  // Get the special ones (float, int, bool and NumericRange).
  floatParams.forEach((p) => {
    if (query.hasOwnProperty(p)) {
      searchParams[p] = parseFloat(query[p] as string);
    }
  });

  intParams.forEach((p) => {
    if (query.hasOwnProperty(p)) {
      searchParams[p] = parseInt(query[p] as string);
    }
  });

  boolParams.forEach((p) => {
    if (query.hasOwnProperty(p)) {
      if (query[p] === 'true' || query[p] === '1') {
        searchParams[p] = true;
      } else if (query[p] === 'false' || query[p] === '0') {
        searchParams[p] = false;
      } else {
        console.error(`Unknown value for boolean "${p}": ${query[p]}`);
      }
    }
  });

  numericRangesParams.forEach((p) => {
    if (query.hasOwnProperty(p)) {
      const numericRanges = getCanonicalNumericRanges(query[p]!);
      if (numericRanges.length > 0) searchParams[p] = numericRanges;
    }
  });

  return {
    ...searchParams,
    filters: [...getSelectedAspectsAsArray(query.filters)],
  };
}

export function getLocationQueryFromSearchParams(
  searchParams: SearchParams
): LocationQuery {
  const query: LocationQuery = {};

  Object.entries(searchParams)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .forEach(([key, value]) => {
      // Don't add the defaults.
      if (
        DEFAULT_SEARCH_PARAMS.hasOwnProperty(key) &&
        isEqual(DEFAULT_SEARCH_PARAMS[key as keyof SearchParams], value)
      ) {
        return;
      }

      for (const p of [...floatParams, ...intParams]) {
        if (key === p) {
          query[key] = String(value);
          return;
        }
      }

      for (const p of boolParams) {
        if (key === p) {
          query[key] === value ? 'true' : 'false';
          return;
        }
      }

      for (const p of numericRangesParams) {
        if (key === p) {
          const canonical: NumericRange[] = canonicalizeNumericRanges(value);
          query[key] = canonical.map((a) => `${a.min},${a.max}`);
          return;
        }
      }

      if (Array.isArray(value)) {
        query[key] = [...value].sort();
        return;
      }

      query[key] = value;
    });
  return { ...query };
}

export function searchParamsToSearchRequest(searchParams: SearchParams): SearchRequest {
  let filters: AndClause[];
  // If we search by id, we ignore all the other filters.
  if (searchParams.id !== undefined) {
    filters = [[{ id: 'id', value: searchParams.id }]];
  } else {
    const selectedAspectIdsByFacets = aspectsModule.groupAspectIdsByFacet(
      (searchParams.filters ?? []).values(),
      /* allowUnknownFacets= */ true
    );

    filters = Array.from(selectedAspectIdsByFacets.values()).map((v: AspectId[]) => {
      return v.map((a) => {
        return { id: a } as OrClause;
      }) as AndClause;
    });

    // if (searchParams.availability !== 'all') {
    //   filters.push([{ id: 'isSold', value: false }]);
    // }
  }

  return {
    twiddlerParams: searchParams,
    // Convert filters to CNF by aspects.
    filters: filters,
    getAspectHistogram: true,
  };
}
