<script setup lang="ts">
// Global.
import { ref } from 'vue';
import { useMq } from 'vue3-mq';

// Framework.
import Header from '../components/Header.vue';
import Browser from '../core/components/Browser.vue';
import Filters from '../core/components/Filters.vue';
import FilterToolbar from '../core/components/FilterToolbar.vue';
import SideBar from '../core/components/SideBar.vue';
import AspectSearch from '../core/components/AspectSearch.vue';
import { AspectId } from 'search-backend';
import { searchModule } from '../core/modules/search-module';

const mq = useMq();

let aspectSearch = ref<typeof AspectSearch | undefined>();
let mobileFilterSideBar = ref<typeof SideBar>();

// function searchParamsFromFacetedSelectedAspects(
//   perFacetSelectedAspects: Map<FacetId, AspectId[]>
// ): SearchParams {
//   const searchParams = { ...searchModule.searchParams };
//   delete searchParams.price;
//   // Make sure that we retain the aspects that are not controlled by visible aspects.
//   const visibleAspectIds: Set<AspectId> = new Set(
//     visibleFacetToAspects.value?.flatMap(([_, aspects]) =>
//       aspects.map((a: VisibleAspect) => a.aspectId)
//     )
//   );
//   searchParams.filters = [...(searchModule.searchParams.filters ?? [])].filter(
//     (a: AspectId) => !visibleAspectIds.has(a)
//   );
//   // For visible aspects, we use the perFacetSelectedAspects map (our vmodel).
//   perFacetSelectedAspects.forEach((aspects: AspectId[], facet: FacetId) => {
//     searchParams.filters?.push(...aspects);
//   });
//   return searchParams;
// }

// function search() {
//   searchModule.doSearch({
//     ...searchParamsFromFacetedSelectedAspects(perFacetCheckedAspects.value),
//     // Remove split facet if we selected an aspect from there.
//     splitFacet:
//       splitFacet.value && perFacetCheckedAspects.value.has(splitFacet.value)
//         ? undefined
//         : splitFacet.value,
//   });
// }

function addAspect(aspectId: AspectId) {
  aspectSearch.value?.closeModal();
  const searchParams = { ...searchModule.searchParams };
  searchParams.filters = [...(searchModule.searchParams.filters ?? []), aspectId];
  searchModule.doSearch(searchParams);
}

function removeAspect(aspectId: AspectId) {
  aspectSearch.value?.closeModal();
  const searchParams = { ...searchModule.searchParams };
  searchParams.filters = [...(searchModule.searchParams.filters ?? [])].filter(
    (a: AspectId) => a != aspectId
  );
  searchModule.doSearch(searchParams);
}
</script>

<template>
  <div class="">
    <Filters
      v-if="mq.mdPlus"
      :showSplitFacetButton="false"
      class="fixed inset-y-0 left-0 w-80 overflow-y-auto border-r border-gray-200"
    />
    <div class="flex flex-col md:ml-80">
      <Header class="max-w-4xl">
        <AspectSearch
          ref="aspectSearch"
          @addAspect="addAspect"
          @removeAspect="removeAspect"
        />
        <FilterToolbar
          class="sticky inset-x-0 z-10 bg-white"
          :filterText="$t('message.filterBar.filterHint')"
          :searchText="$t('message.filterBar.searchHint')"
          @openFilterMenu="mobileFilterSideBar?.setIsOpen(true)"
          @openSearch="aspectSearch?.openModal()"
        />
        <SideBar v-if="!mq.mdPlus" ref="mobileFilterSideBar" :rightSide="false">
          <Filters class="" :showSplitFacetButton="false" />
        </SideBar>
      </Header>

      <Browser @view="" class="max-w-4xl md:border-r md:border-gray-200">
        <!-- <template v-slot:welcomeCard>
            <div class="mx-2">
              <WelcomeCard />
            </div>
          </template> -->
      </Browser>
    </div>
  </div>
</template>
