import { createI18n } from 'vue-i18n';
import { messages } from './messages';

// IF_CHANGE Language
export type Language = 'de';
// THEN_CHANGE ALL_LANGUAGES
export const ALL_LANGUAGES = ['de'];
// END_IF

export const ALL_LANGUAGES_RE = ALL_LANGUAGES.join('|');
export const DEFAULT_LANGUAGE: Language = 'de' as Language;

export function getCurrentLanguage(): Language {
  return DEFAULT_LANGUAGE;
}
/*
export const CANONICAL_URLS: Record<Language, string> = {
  de: 'https://www.teppichportal.ch/shop/',
  en: 'https://en.teppichportal.ch/shop/',
  fr: 'https://fr.teppichportal.ch/shop/',
};
*/

export const i18n = createI18n({
  legacy: false,
  // vue-18n uses Intl.numberFormat, which requires -CH to show apostrophe thousand separators that are used in Switzerland.
  locale: getCurrentLanguage(),
  // For swiss currency to work.
  fallbackLocale: 'de-CH',
  missingWarn: false,
  fallbackWarn: false,
  globalInjection: true,
  messages,
  numberFormats: {
    'de-CH': {
      currency: {
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
        maximumFractionDigits: 0,
      },
    },
  },
});
