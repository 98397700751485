<script setup lang="ts">
import { FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/vue';

import Card from '../../components/Card.vue';
import { Item } from '../../data/repo';
import { CanonicalResultWithAlternatives } from '../interfaces/search-response';
import { logCarouselDepth } from '../logging';
import { ResultGroup } from '../modules/search-module';
import { aspectsModule } from '../modules/aspects-module';

const props = defineProps<{
  resultGroup: ResultGroup;
  aspect: string;
}>();

const emit = defineEmits<{
  (e: 'view', item: CanonicalResultWithAlternatives): void;
}>();

function logIndexChange(swiper: any) {
  if (swiper.activeIndex > 0) {
    logCarouselDepth(
      Math.pow(2, Math.floor(Math.log2(swiper.activeIndex))),
      props.aspect
    );
  }
}
</script>

<!-- TODO: add show all carousel -->

<template>
  <div class="mt-3">
    <span class="">
      <h2 class="pl-2 text-3xl font-bold">
        {{ aspectsModule.getDisplayText(aspect) }}
      </h2>
    </span>
    <swiper
      id="carousel"
      :modules="[FreeMode, Navigation]"
      :free-mode="true"
      :navigation="true"
      slides-per-view="auto"
      class="relative"
      @realIndexChange="logIndexChange"
    >
      <swiper-slide v-for="item in resultGroup.topResults"
        ><Card class="ml-4 mb-4 h-64 w-48" :item="item" @click="emit('view', item)"
      /></swiper-slide>
    </swiper>
  </div>
</template>

<style scoped>
.swiper .swiper-slide,
.swiper-slide {
  width: unset !important;
}
</style>

<style>
.swiper-button-prev,
.swiper-button-next {
  @apply right-2 h-12 w-8 rounded-full bg-yellow-200/90 text-xs text-black shadow-lg focus:ring-0 sm:visible !important;
  --swiper-navigation-size: 1.2rem;
  font-weight: 800;
  /* Compensate for the skewed vertical position. */
  margin-top: -20px;
}
.swiper-button-disabled {
  visibility: hidden;
}
</style>
