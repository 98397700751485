import { AspectId, FacetId } from 'search-backend';
import { AspectMetadata, FacetMetadata } from '../core/interfaces/aspects-metadata';

import aspectCanonicalizationMap from './aspect_canonicalization_map.json';
import aspects from './aspects.json';
import facets from './facets.json';

// GPT-3 prompts:
//
// 1. Consider this json. It contains entries that describe one feature of a holiday
//    camp for children. Extend the table where each row corresponds to one of these
//    aspects, and has columns like title and description, which correspond to a webpage
//    that lists camps with that feature.
//
// ...
//
//TABLE Feature |    Title        | Description
// ------------ | ----------- | ------------------- |
// fullDay      |  Camps providing full day activities   | This is a collection of
// children's holiday camps that provide full day activities.
//
//
// 2. This JSON is used for a website that provides information about children's holiday
//    camps. Translate title and description fields to German.
//

export const manual_aspects = {
  // dailyFormat

  // christmas: {
  //   facet: 'season',
  //   displayText: 'Weihnachten',
  //   metaTitle: 'Weihnachtsferiencamps',
  //   metaDescription: 'Dies ist eine Sammlung von Weihnachtsferiencamps für Kinder.',
  // },
};

export const ASPECTS = new Map<AspectId, AspectMetadata>(
  Object.entries({
    ...aspects,
    ...manual_aspects,
  })
);

const manual_facets = {
  // dailyFormat: {
  //   displayText: 'Format',
  // },
  // genderRestriction: {
  //   displayText: 'Geschlecht',
  // },
  // availability: {
  //   displayText: 'Verfügbarkeit',
  // },
};

export const FACETS = new Map<FacetId, FacetMetadata>(
  Object.entries({
    ...facets,
    ...manual_facets,
  })
);

// Any aspect seen here will be canonicalized to the aspect on the right in navigator.
export const ASPECT_CANONICALIZATION_MAP = new Map<AspectId, AspectId>(
  Object.entries(aspectCanonicalizationMap)
);

// Aspects in this map will be expanded to the implied ones on the right.
export const ASPECT_EXPLODE_MAP = new Map<AspectId, AspectId[]>(
  Object.entries(aspectCanonicalizationMap).reduce((acc, [key, value]) => {
    if (!acc.has(value)) {
      acc.set(value, []);
    }
    acc.get(value)!.push(key);
    return acc;
  }, new Map<AspectId, AspectId[]>())
);

export function withImpliedAspects(
  aspects: AspectId[] | undefined
): AspectId[] | undefined {
  // Is there a single aspect that is in ASPECT_EXPLODE_MAP?
  if (aspects && aspects.length == 1 && ASPECT_EXPLODE_MAP.has(aspects[0])) {
    return ASPECT_EXPLODE_MAP.get(aspects[0])!;
  }
  return aspects;
}
