import {
  Language,
  ALL_LANGUAGES,
  DEFAULT_LANGUAGE,
  getCurrentLanguage,
} from '../../i18n';

// The list of languages is defined in the actual client as it has to be consistent with
// the aspects, facets, etc.

export function validateLanguage(language?: string): Language | undefined {
  return language !== undefined && ALL_LANGUAGES.includes(language)
    ? (language as Language)
    : undefined;
}

export function getFirstMatchingLanguage(languageStrs: readonly string[]) {
  for (let languageStr of languageStrs) {
    const language = validateLanguage(languageStr.substring(0, 2));
    if (language !== undefined) {
      return language;
    }
  }
  return DEFAULT_LANGUAGE;
}

export type LanguageDependentString = string | Record<Language, string>;

export function getSurfaceForm(
  languageDependentString: Readonly<LanguageDependentString> | undefined
): string | undefined {
  // Handle undefined.
  if (languageDependentString === undefined) {
    return undefined;
  }
  // Matching language.
  if (typeof languageDependentString === 'string') {
    // Language independent.
    return languageDependentString as string;
  }
  const language = getCurrentLanguage();
  if (!languageDependentString.hasOwnProperty(language)) {
    // This cannot happen b/c of typescript type checking, but we might import the
    // partially formed string from json, so I keep this check here.
    console.error(`Missing language "${language}" in ${languageDependentString}`);
    return undefined;
  }

  // Handle grammatical case.
  return languageDependentString[language];
}
