export const messages = {
  de: {
    message: {
      contact: 'Kontakt',
      name: 'Name',
      email: 'E-mail',
      question: 'Anfrage',
      alternatives: '+{0} Ausführungen',
      allAlternatives: 'Alle Ausführungen (+{0})',
      // IF_CHANGE: Keep in sync with ItemKeys
      // THEN_CHANGE: ./views/CampPage.ts
      priceRange: 'Preis',
      dateRange: 'Daten',
      dailyFormat: 'Tageszeit',
      ageRange: 'Jahrgang',
      rawLocation: 'Standort',
      // END

      parentaMenu: {
        aboutUs: 'Über uns',
        betaTester: 'Anmeldung',
        reportError: 'Problem melden',
        contactUs: 'Kontaktiere uns',
        addNewCamp: 'Camp hinzufügen',
        impressum: 'Impressum',
        cookies: 'Cookie Einstellungen',
        campProviders: 'Für Campanbieter',
        signup: 'Anmeldung',
      },

      filterBar: {
        filterHint: 'filtern',
        searchHint: 'suchen',
        filterTitle: 'Filter',
      },

      visualSelector: {
        when: 'Wann?',
        sportFerien: 'Sportferien',
        spring: 'Frühling',
        summer: 'Sommerferien',
        fall: 'Herbstferien',
        otherDate: 'Anderes',

        who: 'Wer?',
        kiga: 'KiGa',
        unterstufe: 'Unterstufe',
        mittelstufe: 'Mittelstufe',
        older: 'Älter',

        what: 'Was?',
        arts: 'Kunst',
        crafts: 'Basteln',
        sport: 'Sport',
        science: 'Wissenschaft',
        otherType: 'Anderes',
      },
    },
  },

  fr: {
    message: {
      contact: 'contact',
      name: 'Nom',
      email: 'E-mail',
      question: 'question',
    },
  },
  en: {
    message: {
      contact: 'contact',
      name: 'Name',
      email: 'E-mail',
      question: 'question',
    },
  },
};
