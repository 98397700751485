<script setup lang="ts">
import { Image } from 'search-backend';
import { computed } from 'vue';
import { CanonicalResultWithAlternatives } from '../core/interfaces/search-response';
import { getLocationQueryFromSearchParams } from '../core/libs/search-params';
import { aspectsModule } from '../core/modules/aspects-module';
import { searchModule } from '../core/modules/search-module';
import { VIEWER_ROUTE_NAME } from '../router';
import ProblemData from './ProblemData.vue';

const props = defineProps<{
  item: CanonicalResultWithAlternatives;
}>();

const thumbnailImage = computed(() => {
  const thumbs = props.item?.canonicalResult.images?.filter(
    (i: Image) => i.type === undefined || i.type == 'thumbnail'
  );
  return thumbs && thumbs.length > 0 ? thumbs[0] : undefined;
});
</script>

<template>
  <div
    class="h-auto w-full overflow-hidden rounded-md border border-gray-200 p-2 shadow-lg"
  >
    <router-link
      :to="{
        name: VIEWER_ROUTE_NAME,
        params: { canonicalId: item.canonicalResult.canonicalId },
        query: getLocationQueryFromSearchParams(searchModule.searchParams),
      }"
    >
      <!-- <div class="relative aspect-video w-full rounded-md bg-gray-100">
        <img
          v-if="thumbnailImage"
          class="absolute inset-0 aspect-video w-full rounded-md object-cover object-center"
          :src="thumbnailImage.url"
          width="275"
          height="275"
          :alt="item.canonicalResult.title"
        />
        <span class="absolute bottom-2 right-2 bg-gray-600/50 px-2 text-white">{{
          item.canonicalResult.site
        }}</span>
      </div> -->
      <h2 class="my-2 text-xl text-gray-600 md:text-xl">
        {{ item.canonicalResult.title }}
      </h2>
      <ProblemData :result="item.canonicalResult" :details="false" />
      <p v-if="item.alternatives.length > 0" class="text-sm">
        {{ $t('message.alternatives', [item.alternatives.length]) }}
      </p>
      <p class="mt-2 text-sm text-gray-600 line-clamp-3">
        {{ item.canonicalResult.description }}
      </p>
    </router-link>
    <!-- <pre v-if="item.columns.size > 0">{{ item.columns }}</pre>
    <pre v-for="alternative of item.alternatives">{{ alternative }}</pre> -->
  </div>
</template>
