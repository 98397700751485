import { initLogging, logEvent as logEventCore } from 'logging-lib';
import 'logging-lib/cookieconsent.css';
import { AspectId, FacetId } from 'search-backend';
import { App } from 'vue';
import VueGtag, {
  event as GAEvent,
  PluginOptions,
  pageview as GAPageView,
} from 'vue-gtag';

import { GOOGLE_ANALYTICS_ID, LOGROCKET_ID } from '../logging';

export function logExperiments(experiments: string[]) {
  experiments.forEach((exp: string) => {
    logEventCore(
      'experiments',
      {
        eventCategory: 'BookKeeping',
        event_label: exp,
        non_interaction: true, // for UA, it does not work for GA4.
      },
      /* unique_key =*/ `experiments=${exp}`
    );
  });
}

// Logging events.
export function logViewer(id: string) {
  logEvent('viewer', { event_label: id, event_category: 'interaction' });
}

export function logAddAspect(aspect: AspectId, facet: FacetId) {
  logEventCore('addAspect', { event_label: aspect, event_category: facet });
}

export function logRemoveAspect(aspect: AspectId, facet: FacetId) {
  logEventCore('removeAspect', { event_label: aspect, event_category: facet });
}

export function logFilterPanelInteraction() {
  logEventCore('filterPanelInteraction', {});
}

export function logAspectSearchInteraction(query: string) {
  logEventCore('aspectSearchlInteraction', { event_label: query });
}

export function logToggleSplitView(facet: FacetId) {
  logEventCore('toggleSplitView', { event_label: facet, event_category: facet });
}

export function logFacetCarouselUsed(facet: string) {
  const eventKey = `${facet}`;
  logEventCore(
    'facetCarousel',
    { event_label: facet, event_category: facet },
    /* unique_key= */ eventKey
  );
}

export function logScrollDepth(depth: number, view: string) {
  const eventKey = `${view}_scrollDepth_${depth}`;
  logEventCore(
    'scrollDepth',
    { event_label: String(depth), event_category: view },
    /* unique_key= */ eventKey
  );
}

export function logCarouselDepth(depth: number, aspect: AspectId) {
  const eventKey = `${aspect}_carouselDepth_${depth}`;
  logEventCore(
    'carouselDepth',
    { event_label: String(depth), event_category: aspect },
    /* unique_key= */ eventKey
  );
}

export function logCarouselShowAll(aspect: AspectId) {
  logEventCore('carouselShowAll', {
    event_category: aspect,
  });
}

export function logOpenFilterMenu(view: string) {
  logEventCore('openFilterMenu', {
    event_label: 'openFilterMenu',
    event_category: view,
  });
}

export function logOpenSearch(view: string) {
  logEventCore('openSearch', {
    event_label: 'openSearch',
    event_category: view,
  });
}

// Generic event logging exported for the vertical.
export function logEvent(label: string, payload: any, uniqueKey?: string | undefined) {
  logEventCore(label, payload, uniqueKey);
}

export async function initEventLogger(
  app: App,
  cookieConsentConfigId: string
): Promise<void> {
  const vueGtagConfig: PluginOptions = await initLogging(
    cookieConsentConfigId,
    GOOGLE_ANALYTICS_ID,
    LOGROCKET_ID,
    GAPageView,
    GAEvent
  );
  app.use(VueGtag, vueGtagConfig);
}
