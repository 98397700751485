<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

defineProps<{ rightSide?: boolean }>();

const isOpen = ref<boolean>(false);

function setIsOpen(value: boolean) {
  isOpen.value = value;
}

defineExpose({
  setIsOpen,
});
</script>

<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog @close="setIsOpen(false)" class="relative z-50">
      <!-- The backdrop, rendered as a fixed sibling to the panel container -->
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
      </TransitionChild>

      <!-- Full-screen container to center the panel -->
      <div class="fixed inset-0 z-40">
        <!-- The actual dialog panel -->
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          :enter-from="rightSide ? 'translate-x-full' : '-translate-x-full'"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          :leave-to="rightSide ? 'translate-x-full' : '-translate-x-full'"
        >
          <DialogPanel
            class="flex h-full"
            :class="[rightSide ? 'flex-row-reverse' : 'flex-row']"
          >
            <div
              class="flex w-64 items-stretch justify-between overflow-y-auto overflow-x-clip bg-white"
            >
              <slot></slot>
            </div>
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="p-2" @click="setIsOpen(false)">
                <button
                  type="button"
                  class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-800/50 focus:outline-none focus:ring-black"
                  @click="setIsOpen(false)"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
          </DialogPanel>
        </TransitionChild>
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
        <div class="w-14 flex-shrink-0" aria-hidden="true" />
      </div>
    </Dialog>
  </TransitionRoot>
</template>
