import { isArray, isEqual } from 'lodash';

function hostnameHasLocalhost(): boolean {
  return document.location.hostname.indexOf('localhost') >= 0;
}

export function isProductionMode(): boolean {
  return process.env.NODE_ENV === 'production' && !hostnameHasLocalhost();
}

export function isDevelopmentMode(): boolean {
  return process.env.NODE_ENV === 'development' || hostnameHasLocalhost();
}

export function isTestMode(): boolean {
  return process.env.NODE_ENV === 'test';
}

export function timerStart(name: string) {
  console.log('Timer start: ', name);
  if (isDevelopmentMode()) {
    console.time(name);
  }
}

export function timerStop(name: string) {
  if (isDevelopmentMode()) {
    console.timeEnd(name);
  }
}

export function castToArray<T>(value: undefined | T | T[]): T[] {
  if (value === undefined) return [];
  if (Array.isArray(value)) return value as T[];
  return [value] as T[];
}

export function removeUndefinedValues(obj: any): any {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
}

export function orderInsensitiveArray(a: any, b: any) {
  if (isArray(a) && isArray(b)) {
    return isEqual([...a].sort(), [...b].sort());
  }
}
