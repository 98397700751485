import { logEvent } from './core/logging';

function isLocalHost(): boolean {
  return window.location.href.includes('localhost');
}

/******************************************************************
 * Exports required by the framework
 ******************************************************************/

 export const GOOGLE_ANALYTICS_ID: string = '';
 export const LOGROCKET_ID: string | undefined = undefined;

/******************************************************************
 * Non-framework logging events
 ******************************************************************/
export function exampleLogFunction(_depth: number, _view: string) {
  logEvent('scrollDepth', { event_label: _depth, event_category: _view });
}

// export function logVisitCamp(campUrl: string, campSite: string) {
//   logEvent('visitCamp', { event_label: campUrl, event_category: campSite });
// }

// export function logCampCard(campTitle: string, campSite: string) {
//   logEvent('campCard', { event_label: campTitle, event_category: campSite });
// }

// export function logShareCamp(campUrl: string, campSite: string) {
//   logEvent('shareCamp', { event_label: campUrl, event_category: campSite });
// }

// export function logMenuItem(item: string) {
//   logEvent('menuItem', { event_label: item, event_category: 'interaction' });
// }
