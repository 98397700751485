import { createApp } from "vue"
import { Vue3Mq } from "vue3-mq"

import App from "./App.vue"
import { initEventLogger } from "./core/logging"
import { i18n } from "./i18n"

import "./main.css"

import { exampleLogFunction } from "./logging"
import { router } from "./router"

const app = createApp(App).use(i18n).use(router).use(Vue3Mq, {
  preset: "tailwind",
})
// initEventLogger(app, "hu_hu")

router.isReady().then(() => {
  app.mount("#app")
  exampleLogFunction(0, "home")
})
