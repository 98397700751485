import { isEqual, union } from 'lodash';
import { AspectHistogram, AspectId, ScoredResult } from 'search-backend';
import { Item } from '../../data/repo';

export type ItemKey = keyof Item;
export type ItemValue = Item[ItemKey];

// Representation of a list of items as a table with header.
export type AlternativesTable = {
  header: ItemKey[];
  rows: ItemValue[][];
};

export class CanonicalResultWithAlternatives {
  canonicalResult: Readonly<Item>;
  score: number;
  matchingAspects: AspectId[];
  alternatives: Partial<Readonly<Item>>[] = [];
  columns = new Set<ItemKey>();

  constructor(canonicalResult: ScoredResult<Item>) {
    this.canonicalResult = canonicalResult.product;
    this.score = canonicalResult.score;
    this.matchingAspects = [...canonicalResult.matchingAspects];
  }

  // When the search worker returns the CanoncialResultWithAlternatives object, it's
  // actually only a record with the right member variables but without the member
  // functions. Interestingly, it's handled as if it was a real object... (love you
  // typescript/javascript!). So we need a pseudo-constructor to convert this object
  // into a real class.
  static fromSerializedObject(object: {
    canonicalResult: Readonly<Item>;
    score: number;
    matchingAspects: AspectId[];
    alternatives: Partial<Readonly<Item>>[];
    columns: Set<ItemKey>;
  }): CanonicalResultWithAlternatives {
    const result = new CanonicalResultWithAlternatives({
      product: object.canonicalResult,
      score: object.score,
      matchingAspects: object.matchingAspects,
    });
    result.alternatives = [...object.alternatives];
    result.columns = new Set(object.columns);
    return result;
  }

  public addAlternative(item: ScoredResult<Item>) {
    // Only add an alternative if its score > 0.
    // TODO(dienes) Remove the special casing in search-backend and then we don't need
    // this here.
    if (item.score === 0.0) return;

    // Get the keys where this item and the canonical differ.
    const keys = union(Object.keys(this.canonicalResult), Object.keys(item.product))
      .filter((k: string) => !['images', 'aspects', 'qualityScore'].includes(k))
      .map((k: string) => k as ItemKey);
    const new_item = new Map<string, any>();
    for (let key of keys) {
      if (!isEqual(this.canonicalResult[key], item.product[key])) {
        new_item.set(key, item.product[key]);
        this.columns.add(key);
      }
    }

    this.alternatives.push(Object.fromEntries(new_item.entries()) as Partial<Item>);
  }

  // Produces a table where each row is one alternative (including the canonical if
  // includeCanonical) is true. Each column is one attribute from eligibleColumns (in
  // the same order) where the alternatives differ. The header contains the these
  // columns.
  public getAlternativesTable(
    eligibleColumns: ItemKey[],
    includeCanonical: boolean = true
  ): AlternativesTable {
    const header: ItemKey[] = eligibleColumns.filter((column: ItemKey) =>
      this.columns.has(column)
    );
    const rows: ItemValue[][] = [];
    if (includeCanonical) {
      rows.push(header.map((c: ItemKey) => this.canonicalResult[c]));
    }
    this.alternatives.forEach((alternative: Partial<Readonly<Item>>) => {
      rows.push(
        header.map((c: ItemKey) => {
          if (alternative.hasOwnProperty(c)) {
            return alternative[c];
          } else {
            return this.canonicalResult[c];
          }
        })
      );
    });

    rows.sort();
    return { header, rows };
  }
}

export type GroupedSearchResponse = {
  results: CanonicalResultWithAlternatives[];
  numResults: number;
  aspectHistogram?: AspectHistogram;
};
