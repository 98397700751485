<script setup lang="ts">
import { experimentParams } from 'experiments-lib';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import Card from '../../components/Card.vue';
import { VISUAL_SELECTORS } from '../../data/aspects';
import { CanonicalResultWithAlternatives } from '../interfaces/search-response';
import { logScrollDepth } from '../logging';
import { searchModule } from '../modules/search-module';
import Carousel from './Carousel.vue';
import VisualAspectCarousel from './VisualAspectCarousel.vue';
import InfiniteLoading from 'v3-infinite-loading';
import 'v3-infinite-loading/lib/style.css'; //required if you're not going to override default slots

// Before the images and fonts arrive, the estimated height of the grid might be off,
// and we should make sure we don't end up with the early height suggesting we have
// enough elements, but then the final height being lower.
const EARLY_HEIGHT_OFF_PIXELS = 300;

const emit = defineEmits<{
  (e: 'view', item: CanonicalResultWithAlternatives): void;
}>();

let infiniteLoadRef = ref<typeof InfiniteLoading>();

const items = computed(() => {
  return searchModule.searchResultsToShow;
});

async function loadMore(state: any) {
  // This is needed with v3-infinite-load 1.2.1 to avoid infinite loop.
  await new Promise((f) => setTimeout(f, 10));
  searchModule.loadMore().then((isDone: boolean | undefined) => {
    const searchDepth = Math.floor(
      Math.floor(searchModule.searchResultsToShow.length / 50) * 50
    );
    // if (searchDepth > 0) {
    //   eventLogger.logScrollDepth(searchDepth, 'ProductBrowser');
    // }
    if (isDone !== undefined && isDone === true) {
      state?.complete();
    } else {
      state?.loaded();
    }
  });
}

async function ensureEnoughLoaded() {
  // infiniteLoadDisabled.value = false;
  // if (!infiniteLoadRef.value) {
  //   return;
  // }
  // // Make sure first screen is filled with items, but avoid infinite loop in case we miss the box sizes that are generated later than nextTick().
  // let maxCount = 5;
  // while (
  //   maxCount-- > 0 &&
  //   infiniteLoadRef.value.$el.getBoundingClientRect().top - EARLY_HEIGHT_OFF_PIXELS <=
  //     (window.innerHeight || document.documentElement.clientHeight)
  // ) {
  //   await loadMore(infiniteLoadRef.value.state);
  //   // Let's hope that in 100ms the boxes have been computed.
  //   await new Promise((resolve) => setTimeout(resolve, 100));
  // }
}

// When search params are changed, a new search is started. We need to fill the screen
// once the results are ready.
// TODO(zsolt): it would be better to trigger this when the results are ready.
// watch(
//   () => searchModule.searchParams,
//   () => {
//     // TODO(zsolt): scroll to top does not work on iOS somehow.
//     document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
//     nextTick().then(ensureEnoughLoaded);
//   }
// );

// onMounted(ensureEnoughLoaded);
</script>

<template>
  <div>
    <slot name="welcomeCard"></slot>
    <div v-if="experimentParams().useVisualSelectors" class="divide-y">
      <VisualAspectCarousel v-for="selector in VISUAL_SELECTORS" :selector="selector" />
    </div>
    <template v-if="searchModule.searchParams.splitFacet">
      <Carousel
        v-for="([type, resultGroup], index) in searchModule.groupedResults"
        :aspect="type"
        :resultGroup="resultGroup"
        @view="(item) => emit('view', item)"
      />
    </template>
    <div
      v-else
      class="grid place-items-stretch gap-3 p-2 lg:grid-cols-2 xl:grid-cols-3"
      :class="[
        experimentParams().singleColumnOnMobile ? 'grid-cols-1' : 'grid-cols-2',
        experimentParams().zoomedInImages ? 'gap-2' : '',
      ]"
    >
      <Card
        v-for="item in items"
        :key="item.canonicalResult.id"
        :item="item"
        @click="emit('view', item)"
      />

      <!-- IMPORTANT: v3-infinite-load changes firstLoad to firstload on 1.2.0. -->
      <InfiniteLoading
        @infinite="loadMore"
        :distance="1000"
        :firstload="false"
        :identifier="searchModule.searchParams"
        ref="infiniteLoadRef"
      >
        <template #complete>
          <div><!-- empty --></div>
        </template>
      </InfiniteLoading>
    </div>
    <slot name="goodbyeCard"></slot>
  </div>
</template>
