import { AspectId, FacetId } from 'search-backend';
import { TwiddlerParams } from '../../libs/twiddler';

// Ranges correspond to inclusive intervals [min, max].
export interface NumericRange {
  min: number;
  max: number;
}

export type NumericRanges = NumericRange | NumericRange[];

export interface FilterParams {
  filters?: AspectId[];
  width?: NumericRanges;
  height?: NumericRanges;
  price?: NumericRanges;
  id?: string;
}

interface DisplayParams {
  splitFacet?: FacetId;
}

export interface SearchParams extends FilterParams, TwiddlerParams, DisplayParams {}

export function numericRangesLength(ranges: NumericRanges | undefined): number {
  if (ranges === undefined) {
    return 0;
  } else if (Array.isArray(ranges)) {
    return ranges.length;
  } else {
    return 1;
  }
}
