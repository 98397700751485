<script setup lang="ts">
import {
  XMarkIcon,
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/vue/24/outline';
import { AspectId } from 'search-backend';
import { ref, watch } from 'vue';
import { AspectMetadata } from '../interfaces/aspects-metadata';
import { getSurfaceForm } from '../interfaces/language';
import { logOpenFilterMenu, logOpenSearch, logRemoveAspect } from '../logging';
import { aspectsModule } from '../modules/aspects-module';
import { searchModule } from '../modules/search-module';
import { useMq } from 'vue3-mq';

const mq = useMq();

defineProps<{ filterText?: string; searchText?: string }>();

const emit = defineEmits<{
  (e: 'openFilterMenu'): void;
  (e: 'openSearch'): void;
}>();

function openFilterMenu() {
  logOpenFilterMenu('BrowserPage');
  emit('openFilterMenu');
}

function openSearch() {
  logOpenSearch('BrowserPage');
  emit('openSearch');
}

type AspectChip = {
  id: AspectId;
  text: string;
  color: string;
};

const aspectChips = ref<AspectChip[]>();

watch(
  () => [searchModule.searchParams, aspectsModule.facetsToAspectIds],
  () => {
    const filters: AspectId[] = [...(searchModule.searchParams.filters ?? [])];
    const aspectsByFacet = aspectsModule.groupAspectIdsByFacet(filters.values());
    aspectChips.value = [...aspectsByFacet.values()].flatMap((aspects: AspectId[]) => {
      return aspects.map((aspect) => {
        const aspectMeta: AspectMetadata = aspectsModule.aspects.get(aspect)!;
        return {
          id: aspect,
          text:
            getSurfaceForm(aspectMeta.shortDisplayText ?? aspectMeta.displayText) ?? '',
          color:
            aspectsModule.facets.get(aspectMeta.facet ?? '')?.chipColor ?? 'lightgray',
        };
      });
    });
  },
  {
    immediate: true,
  }
);

function removeAspect(aspect: AspectId) {
  let updatedSearchParams = {
    ...searchModule.searchParams,
    filters: searchModule.searchParams.filters?.filter(
      (selectedAspect: AspectId) => selectedAspect != aspect
    ),
  };
  logRemoveAspect(aspect, aspectsModule.getFacet(aspect) ?? 'UnknownFacet');
  searchModule.doSearch(updatedSearchParams);
}
</script>

<template>
  <div class="flex flex-row items-center justify-between">
    <!-- Filter button with icon and optional text. -->
    <div
      v-if="!mq.mdPlus"
      @click="openFilterMenu"
      class="flex items-center gap-2 border-r p-2"
    >
      <AdjustmentsHorizontalIcon class="h-8 w-8" />
      <div v-if="aspectChips?.length == 0 && filterText" class="text-sm">
        {{ filterText }}
      </div>
    </div>
    <h1
      class="touch-scroll no-scrollbar flex flex-nowrap items-center gap-2 overflow-x-scroll px-2"
    >
      <span
        v-for="aspect of aspectChips"
        class="chip shrink-0 select-none"
        :style="'background-color: ' + aspect.color + ';'"
        @click="removeAspect(aspect.id)"
      >
        {{ aspect.text }}
        <XMarkIcon class="w-4 py-2" />
      </span>
    </h1>
    <!-- Search icon. -->
    <div @click="openSearch" class="flex items-center gap-2 border-l p-2">
      <div v-if="aspectChips?.length == 0 && searchText" class="text-sm">
        {{ searchText }}
      </div>
      <MagnifyingGlassIcon class="h-8 w-8" />
    </div>
  </div>
</template>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  /* Chrome, Safari and Opera */
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.chip {
  @apply flex h-8 items-center gap-1 rounded-full pl-3 pr-2;
}
</style>
